import { Http, Request, RequestOptionsArgs, Response, RequestOptions, XHRBackend, Headers } from '@angular/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/throw';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/finally';
import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

// const baseUrl = 'http://192.168.188.33:8085';
// const baseUrl = 'http://192.168.40.20:82';  // P K
// const baseUrl = 'http://104.248.153.234';
// const baseUrl = 'http://178.128.56.183';
// const baseUrl = 'http://10.0.0.100:8085';
// const baseUrl = 'http://178.128.56.183:81';
const baseUrl = 'http://exrate.islamicex.com:81'; // ISLAMIC
// const baseUrl = 'https://unimoniratesapi.thoughtbox.io'  // UNIMONI

@Injectable()
export class HttpInterceptor extends Http {

    constructor(backend: XHRBackend, defaultOptions: RequestOptions, private _router: Router, private spinner: NgxSpinnerService) {
        super(backend, defaultOptions);
    }

    request(url: string | Request, options?: RequestOptionsArgs): Observable<Response> {
        return this.intercept(super.request(url, this.getRequestOptionArgs(options)));
    }

    get(url: string, options?: RequestOptionsArgs): Observable<Response> {
        this.spinner.show();

        return this.intercept(super.get(baseUrl + url, this.getRequestOptionArgs(options)))
            .finally(() => {
                this.spinner.hide();

            });
    }

    post(url: string, body: string, options?: RequestOptionsArgs): Observable<Response> {
        this.spinner.show();

        return this.intercept(super.post(baseUrl + url, body, this.getRequestOptionArgs(options)))
            .finally(() => {
                this.spinner.hide();

            });
    }

    put(url: string, body: string, options?: RequestOptionsArgs): Observable<Response> {
        return this.intercept(super.put(baseUrl + url, body, this.getRequestOptionArgs(options)))
            .finally(() => {
            });
    }

    delete(url: string, options?: RequestOptionsArgs): Observable<Response> {
        return this.intercept(super.delete(baseUrl + url, options))
            .finally(() => {
            });
    }

    getRequestOptionArgs(options?: RequestOptionsArgs): RequestOptionsArgs {
        if (options == null) {
            options = new RequestOptions();
        }
        if (options.headers == null) {
            options.headers = new Headers();
        }
        // if (!(<any>options).doNotChangeContentType) {
        //     options.headers.set('Content-Type', 'application/json');

        // }
        // const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        // console.log('******CYURENNT USER', currentUser);
        // const token = currentUser && currentUser.responsedata.lasttoken;
        // options.headers.append('Authorization', `Bearer ${token}`);
        // console.log('****THIS>IS OPTIONS', options);
        return options;
    }

    intercept(observable: Observable<Response>): Observable<Response> {
        return observable;
        // .catch((err, source) => {
        // 	if (err.status  == 401 && !_.endsWith(err.url, 'api/auth/login')) {
        //         this._router.navigate(['/authentication/signin']);
        //         Observable.empty();
        //     } else {
        //         return Observable.throw(err);
        // 	}
        // });

    }
}
