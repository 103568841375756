import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { HttpInterceptor } from '../extended-services/http-interceptor';
import { first } from 'rxjs/operators';
import { Headers, RequestOptions, Response } from '@angular/http';

import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  constructor(private http: HttpInterceptor) { }

  login(username: string, password: string) {
    return this.http.post(`/users/login`, JSON.stringify({ username: username, password: password }))
      .pipe(map((userDetails: any) => {
        const user = JSON.parse(userDetails._body);
        if (user && user.tokenstatus === 'true') {
          const token1 = { token: user.responsedata.lasttoken };
          localStorage.setItem('currentUser', JSON.stringify(token1));
        }
        return user;
      }));
  }

  getAllByBranch() {

    const headers = new Headers({ 'Content-Type': 'application/json' });
    const options = new RequestOptions({ headers: headers });
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const token = currentUser.token;
    options.headers.append('Authorization', `Bearer ${token}`);

    return this.http.get(`/ratecontrol/getrates`, options)
      .map(
        (data: any) => {
          const ratecontrol = JSON.parse(data._body);
          localStorage.removeItem('currentUser');
          const token1 = { token: ratecontrol.renewedtoken };
          localStorage.setItem('currentUser', JSON.stringify(token1));
          return ratecontrol;
        },
        error => {
          console.log('Error');
          console.log(error);
          return error;
        });
  }


  getAllchildrenMin() {

    const headers = new Headers({ 'Content-Type': 'application/json' });
    const options = new RequestOptions({ headers: headers });
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const token = currentUser.token;
    options.headers.append('Authorization', `Bearer ${token}`);

    return this.http.get(`/companyprofile/getallchildrenmin`, options)
      .map(
        (data: any) => {
          const branche = JSON.parse(data._body);
          localStorage.removeItem('currentUser');
          const token1 = { token: branche.renewedtoken };
          localStorage.setItem('currentUser', JSON.stringify(token1));
          return branche;
        },
        error => {
          console.log('Error');
          console.log(error);
          return error;
        });
  }

  updateByBranch(updateData) {

    const headers = new Headers({ 'Content-Type': 'application/json' });
    const options = new RequestOptions({ headers: headers });
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const token = currentUser.token;
    options.headers.append('Authorization', `Bearer ${token}`);

    return this.http.post(`/ratecontrol/updateforex`, updateData, options)
      .map(
        (data: any) => {
          const ratecontrol = JSON.parse(data._body);
          localStorage.removeItem('currentUser');
          const token1 = { token: ratecontrol.renewedtoken };
          localStorage.setItem('currentUser', JSON.stringify(token1));
          return ratecontrol;
        },
        error => {
          console.log('Error');
          console.log(error);
          return error;
        });
  }

  searchByBranch(branchid) {

    const headers = new Headers({ 'Content-Type': 'application/json' });
    const options = new RequestOptions({ headers: headers });
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const token = currentUser.token;
    options.headers.append('Authorization', `Bearer ${token}`);

    return this.http.get(`/ratecontrol/getbybranch/${branchid}`, options)
      .map(
        (data: any) => {
          const ratecontrol = JSON.parse(data._body);
          localStorage.removeItem('currentUser');
          const token1 = { token: ratecontrol.renewedtoken };
          localStorage.setItem('currentUser', JSON.stringify(token1));
          return ratecontrol;
        },
        error => {
          console.log('Error');
          console.log(error);
          return error;
        });
  }
}
