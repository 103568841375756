import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
// import { AuthenticationService } from '../services/authentication/authentication.service';
// import { Observable } from 'rxjs/Observable';
// import 'rxjs/add/observable/zip';
import { first } from 'rxjs/operators';
import { copyStyles } from '@angular/animations/browser/src/util';
// import { ToastrService } from 'ngx-toastr';
// import * as $ from 'jquery';
declare var $: any;
import { GeneralService } from '../services/general/general.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../environments/environment.prod';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  submitted: Boolean;
  inputType: String = 'password';
  showHideClass: any = 'fas fa-eye';
  login_logo = environment.login_logo;

  constructor(private fb: FormBuilder,
    private authService: GeneralService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService
  ) { }

  ngOnInit() {

    this.logout();
    this.loginForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  get f() { return this.loginForm.controls; }
  get v() { return this.loginForm.value; }

  // this.v.email, this.v.password

  login() {
    this.submitted = true;
    if (this.loginForm.valid) {

      this.authService.login(this.v.email, this.v.password)
        .pipe(first())
        .subscribe(
          data => {

            console.log('data', data);
            

            if (data.responsestatus === 'TRUE') {
              this.router.navigate(['/main']);
            } else {
              this.toastr.error('Invalid Credentials', 'Error!');
            }
          }, error => {
            console.log('**ERrOR', error);
          });
    }
  }

  // login() {
  //   this.router.navigate(['main']);
  // }

  hideShowPassword() {
    if (this.inputType === 'password') {
      this.inputType = 'text';
      this.showHideClass = 'fas fa-eye-slash';
    } else {
      this.inputType = 'password';
      this.showHideClass = 'fas fa-eye';
    }
  }

  logout() {
    localStorage.removeItem('currentUser');
  }
}











