import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GeneralService } from '../services/general/general.service';
import {
  NgbModal,
  ModalDismissReasons,
  NgbPanelChangeEvent,
  NgbCarouselConfig
 } from '@ng-bootstrap/ng-bootstrap';
 import { environment } from '../../environments/environment.prod';


@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  options = {
    theme: 'light', // two possible values: light, dark
    dir: 'ltr', // two possible values: ltr, rtl
    layout: 'vertical', // fixed value. shouldn't be changed.
    headerpos: 'fixed', // two possible values: fixed, absolute
    boxed: 'full', // two possible values: full, boxed
    navbarbg: 'skin1', // six possible values: skin(1/2/3/4/5/6)
    logobg: 'skin6' // six possible values: skin(1/2/3/4/5/6)
  };

  dateTime: any;
  allRates: any;
  login_logo = environment.login_logo;
  showFirstExchange: Boolean = false;
  first_exchange: any;

  constructor(private router: Router,
    private modalService: NgbModal,
    private generalService: GeneralService) { }

  ngOnInit() {
    // this.generalService.getAllByBranch()
    // .subscribe(data => {
    //   this.allRates = data.responsedata;
    //   console.log('***THIS IS ALL RATES', this.allRates);
    // });

    if(environment.first_exchange !== '') {
      this.showFirstExchange = true;
      this.first_exchange = environment.first_exchange;
    } else {
      this.showFirstExchange = false;
    }
  }

  listExchange() {
    this.router.navigate(['list-exchange-rates']);
  }

  updateExchange() {
    this.router.navigate(['update-exchange-rates']);
  }

  logout() {
    localStorage.removeItem('currentUser');
    this.router.navigate(['']);
  }

  open2(content) {
    this.modalService.open(content).result.then(
      result => {
        console.log('Modal Closed');
      },
      reason => {
        console.log('No Reason');
      }
    );
  }
}
