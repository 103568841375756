import { ModuleWithProviders, Component } from '@angular/core';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './../dashboard/dashboard.component';
import { MainComponent } from '../main/main.component';
// import { DashboardComponent } from './dashboard.component';
import { ListexchangeComponent } from './../listexchange/listexchange.component';
import { UpdateexchangeComponent } from './../updateexchange/updateexchange.component';

const dashboardRoutes: Routes = [
    {
        path: '',
        component: DashboardComponent,
        children: [
            {
                path: 'main', component: MainComponent,
                data: {}
            },
            {
                path: 'list-exchange-rates', component: ListexchangeComponent,
                data: {}
            },
            {
                path: 'update-exchange-rates', component: UpdateexchangeComponent,
                data: {}
            }

        ],
    }
];

export const DashboardRouting: ModuleWithProviders = RouterModule.forRoot(dashboardRoutes);


export const DashboardComponents =
    [
        DashboardComponent,
        MainComponent,
        ListexchangeComponent,
        UpdateexchangeComponent
    ];
