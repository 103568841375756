// export const environment = {
//   baseUrl: 'http://192.168.40.20:82',  // P K
//   production: true,
//   header: 'Purshottam Kanji Exchange co. LLC',
//   address: 'Souk Al Khuwair St, Muscat, Oman',
//   login_logo: 'assets/images/islamic/pklogo.png',
//   first_exchange: 'assets/images/islamic/firstexchange.png',
//   currency: 'OMR',
//   imageurl: 'http://192.168.40.30:81',  
// };


export const environment = {
  baseUrl: 'http://exrate.islamicex.com',  // ISLAMIC
  production: true,
  header: 'Islamic Exchange',
  address: 'Doha, Qatar',
    login_logo: 'assets/images/islamic/islamiclogo.jpg',
  first_exchange: '',
  currency: 'QAR',
  imageurl: 'http://exrate.islamicex.com',  
};

// export const environment = {
//   baseUrl: 'https://unimoniratesapi.thoughtbox.io',  // UniMoni
//   production: true,
//   header: 'Universal Money Qatar',
//   address: 'Doha, State Of Qatar',
//   login_logo: 'assets/images/islamic/unimoni.png',
//   first_exchange: '',
//   currency: 'QAR',
//   imageurl: 'https://unimonirates.thoughtbox.io',
// };
