import { Component, OnInit } from '@angular/core';
import {
  NgbModal,
  ModalDismissReasons,
  NgbPanelChangeEvent,
  NgbCarouselConfig
} from '@ng-bootstrap/ng-bootstrap';
import { GeneralService } from '../services/general/general.service';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { FormControl } from '@angular/forms';
import { NgModel } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment.prod';
import * as XLSX from 'xlsx';
const { read, write, utils } = XLSX;
import { NgxSpinnerService } from 'ngx-spinner';

import * as $ from 'jquery';
import { IfStmt } from '@angular/compiler';
@Component({
  selector: 'app-updateexchange',
  templateUrl: './updateexchange.component.html',
  styleUrls: ['./updateexchange.component.scss']
})
export class UpdateexchangeComponent implements OnInit {
  options = {
    theme: 'light', // two possible values: light, dark
    dir: 'ltr', // two possible values: ltr, rtl
    layout: 'vertical', // fixed value. shouldn't be changed.
    headerpos: 'fixed', // two possible values: fixed, absolute
    boxed: 'full', // two possible values: full, boxed
    navbarbg: 'skin1', // six possible values: skin(1/2/3/4/5/6)
    logobg: 'skin6' // six possible values: skin(1/2/3/4/5/6)
  };
  currentDate: any;
  currentTimeString: any;
  allRates: any;
  branches: any;

  toppings = new FormControl();
  updateData: any;

  selectedYears: any[];
  years: any[];
  checkAllVar: Boolean = false;
  selectedBranch: any = '';
  ratesArray: any[] = [];
  selectedRates: any[] = [];
  login_logo = environment.login_logo;
  showFirstExchange: Boolean = false;
  first_exchange: any;
  bulkUploadModalRef: any;
  bulkuploadfile = new FormControl();
  selectedFile: any;

  bulkMultiArray: any[][];

  constructor(private generalService: GeneralService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private router: Router) { }

  ngOnInit() {

    if (environment.first_exchange !== '') {
      this.showFirstExchange = true;
      this.first_exchange = environment.first_exchange;
    } else {
      this.showFirstExchange = false;
    }

    this.UpdateCurrentDate();
    // this.UpdateClock();

    setInterval(this.UpdateClock, 0);

    this.generalService.getAllByBranch()
      .subscribe(data => {
        this.allRates = data.responsedata;
      });

    this.generalService.getAllchildrenMin()
      .subscribe(data => {
        this.branches = data.responsedata;
        console.log('BRANCHES', this.branches);

      });

    this.updateData = {
      branch: [],
      rates: []
    };
  }

  selectAll(select: NgModel, values) {
    select.update.emit(values);
  }

  deselectAll(select: NgModel) {
    select.update.emit([]);
  }

  UpdateCurrentDate() {

    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const today = new Date();
    this.currentDate = today.getDate() + ' ' + monthNames[today.getMonth()] + ' ' + today.getFullYear();
    $('#clock2').html(this.currentDate + '');
    return this.currentDate;
  }

  UpdateClock() {

    const currentTime = new Date();
    let currentHours = currentTime.getHours();
    const currentMinutes = currentTime.getMinutes();
    const currentSeconds = currentTime.getSeconds();
    const Minutes = (currentMinutes < 10 ? '0' : '') + currentMinutes;
    const Seconds = (currentSeconds < 10 ? '0' : '') + currentSeconds;
    const timeOfDay = (currentHours < 12) ? 'AM' : 'PM';
    currentHours = (currentHours > 12) ? currentHours - 12 : currentHours;
    currentHours = (currentHours === 0) ? 12 : currentHours;
    this.currentTimeString = currentHours + ':' + Minutes + ':' + Seconds + ' ' + timeOfDay;
    $('#clock').html(this.currentTimeString);
  }

  update(selectedYears, myForm: NgForm) {

    this.selectedRates = [];

    if (selectedYears === undefined) {
      this.toastr.error('Branch must be selected', 'Error!');
    }
    // Branch should be taken from company profile //


    if (myForm.valid && selectedYears !== undefined) {
      for (const item in this.ratesArray) {
        if (this.ratesArray[item].checked === true) {
          this.selectedRates.push(this.ratesArray[item]);
        }
      }
      this.updateData = {
        branch: this.selectedYears,
        rates: this.selectedRates
      };

      console.log('****THESE ARE ARRAYS TO BE PUSHED', this.selectedRates);
      this.generalService.updateByBranch(this.updateData)
        .subscribe(data => {
          this.toastr.success('Successfully Updated', 'Success!');
        });
    } else {
      console.log('***ERROR : Form not valid');
      this.toastr.error('Please fill all blank fields', 'Sorry!');
    }

    for (const item in this.ratesArray) {
      if (item) {
        this.ratesArray[item].checked = false;
      }
    }

  }


  mainDashboard() {
    this.router.navigate(['/main']);
  }


  searchByBranch() {
    this.ratesArray = [];
    console.log('*****SELECTED BRANCH', this.selectedBranch);
    this.generalService.searchByBranch(this.selectedBranch.id)
      .subscribe(data => {
        console.log('***BRANCH DATA', data.responsedata);
        if (data.responsestatus.toLowerCase() === 'true') {
          this.ratesArray = data.responsedata;
          this.toastr.success('Search Successful', 'Success!');
        } else {
          this.toastr.error('Error', 'Error!');
        }
      });
  }


  checkAll() {
    if (this.checkAllVar === true) {
      for (const item in this.ratesArray) {
        if (item) {
          this.ratesArray[item].checked = true;
        }
      }
      console.log('*******this.checkAllVar', this.checkAllVar);
      console.log('*******HEHEHEHEHE BOI', this.ratesArray);

    } else if (this.checkAllVar === false) {
      for (const item in this.ratesArray) {
        if (item) {
          this.ratesArray[item].checked = false;
        }
      }
    }
  }

  openBulkUploadModal(content) {
    this.bulkUploadModalRef = this.modalService.open(content, { windowClass: 'bulkUpload', centered: true });
  }

  uploadFile() {

    console.log('bulkuploadfile', this.bulkuploadfile.value);
    if (this.bulkuploadfile.value !== null) {
      for (let item = 0; item < this.ratesArray.length; item++) {
        for (let elem = 0; elem < this.bulkMultiArray.length; elem++) {
          if (this.ratesArray[item].currencycode === this.bulkMultiArray[elem][0]) {
            this.ratesArray[item].forexbuyrate = this.bulkMultiArray[elem][1];
            this.ratesArray[item].forexsellrate = this.bulkMultiArray[elem][2];
            this.ratesArray[item].forexremittancerate = this.bulkMultiArray[elem][3];
            this.ratesArray[item].checked = true;
          }
        }
      }

      this.bulkUploadModalRef.close();
      this.toastr.success('Successfully Uploaded', 'Success!')
    } else {
      this.toastr.error('Please Select File!', 'Error!')
    }
  }

  onFileChange(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.bulkMultiArray = (XLSX.utils.sheet_to_json(ws, { header: 1 }));
      console.log(this.bulkMultiArray);
    };
    reader.readAsBinaryString(target.files[0]);
  }

}
