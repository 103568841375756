import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { DashboardRouting, DashboardComponents } from './dashboard/dashboard.routing';
import { OwlModule } from 'ngx-owl-carousel';
import { HttpModule } from '@angular/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatListModule, MatSelectModule, MatOptionModule, MatButtonModule } from '@angular/material';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HeaderComponent } from './dashboard/components/header/header.component';
import { MainComponent } from './main/main.component';
import { ListexchangeComponent } from './listexchange/listexchange.component';
import { UpdateexchangeComponent } from './updateexchange/updateexchange.component';
import { HttpInterceptor } from './services/extended-services/http-interceptor';
import { GeneralService } from './services/general/general.service';
import { ToastrModule } from 'ngx-toastr';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { NgxAutoScrollModule } from 'ngx-auto-scroll';
import { NgxNewstickerAlbeModule } from 'ngx-newsticker-albe';
import { NgMarqueeModule } from 'ng-marquee';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { MatIconModule } from '@angular/material/icon';

import * as $ from 'jquery';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    HeaderComponent,
    MainComponent,
    ListexchangeComponent,
    UpdateexchangeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule.forRoot(),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DashboardRouting,
    OwlModule,
    HttpModule,
    ToastrModule.forRoot({ maxOpened: 1, autoDismiss: true }),
    BrowserAnimationsModule,
    MatSelectModule,
    MatListModule,
    MatOptionModule,
    MatButtonModule,
    NgxAutoScrollModule,
    NgxNewstickerAlbeModule,
    NgMarqueeModule,
    NgxSpinnerModule,
    MaterialFileInputModule,
    MatIconModule
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    HttpInterceptor,
    GeneralService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
