import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '../environments/environment.prod';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'ExchangeRates';

  constructor(private titleService: Title) { }

  ngOnInit() {

    if (environment.header === 'Islamic Exchange') {
      this.titleService.setTitle('Islamic Exchange');
    } else if (environment.header === 'Universal Money Qatar') {
      this.titleService.setTitle('Universal Money Qatar');
    } else {
      this.titleService.setTitle('PK Exchange');
    }
  }

}
