// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   baseUrl: 'http://192.168.40.20:82',  // P K
//   production: false,
//   header: 'Purshottam Kanji Exchange co. LLC',
//   address: 'Souk Al Khuwair St, Muscat, Oman',
//   login_logo: 'assets/images/islamic/pklogo.png',
//   first_exchange: 'assets/images/islamic/firstexchange.png',
//   currency: 'OMR',
//   imageurl: 'http://192.168.40.30:81',  
// };


export const environment = {
  baseUrl: 'http://exrate.islamicex.com',  // ISLAMIC
  production: false,
  header: 'Islamic Exchange',
  address: 'Doha, Qatar',
  login_logo: 'assets/images/islamic/islamiclogo.jpg',
  currency: 'QAR',
  first_exchange: '',
  imageurl: 'http://exrate.islamicex.com',  
};


// export const environment = {
//   baseUrl: 'https://unimoniratesapi.thoughtbox.io',  // UniMoni
//   production: false,
//   header: 'Universal Money Qatar',
//   address: 'Doha, State Of Qatar',
//   login_logo: 'assets/images/islamic/unimoni.png',
//   first_exchange: '',
//   currency: 'QAR',
//   imageurl: 'https://unimonirates.thoughtbox.io',
// };




/// Change Slider Function in LIST EXCHANGE RATES AS PER NEED


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
