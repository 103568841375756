import { Component, OnInit, ViewChild } from '@angular/core';
import {
  NgbModal,
  ModalDismissReasons,
  NgbPanelChangeEvent,
  NgbCarouselConfig
} from '@ng-bootstrap/ng-bootstrap';
// import 'bootstrap';
import * as $ from 'jquery';
import * as marqueejs from 'src/assets/js/marquee.js';
import { GeneralService } from '../services/general/general.service';
// import {Observable} from 'rxjs/Observable';
// import 'rxjs/add/observable/interval';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/interval';
import { Router } from '@angular/router';
import { interval } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { environment } from '../../environments/environment.prod';


declare const SimpleMarquee: any;

@Component({
  selector: 'app-listexchange',
  templateUrl: './listexchange.component.html',
  styleUrls: ['./listexchange.component.scss']
})
export class ListexchangeComponent implements OnInit {

  arrayHere = ['aasdladkal;d', 'adadadad', 'asdadadad', 'asdadadasd', 'asdadadada'];
  login_logo = environment.login_logo;

  favorriteInterval: any;
  currentDate: any;
  currentTimeString: any;
  allRates: any[] = [];
  favourites: any[] = [];
  sub: any;
  options = {
    theme: 'light', // two possible values: light, dark
    dir: 'ltr', // two possible values: ltr, rtl
    layout: 'vertical', // fixed value. shouldn't be changed.
    headerpos: 'fixed', // two possible values: fixed, absolute
    boxed: 'full', // two possible values: full, boxed
    navbarbg: 'skin1', // six possible values: skin(1/2/3/4/5/6)
    logobg: 'skin6' // six possible values: skin(1/2/3/4/5/6)
  };

  showFirstExchange: Boolean = false;
  first_exchange: any;
  currency = environment.currency;;


  constructor(private config: NgbCarouselConfig,
    private generalService: GeneralService,
    private router: Router) {
    config.interval = 5000;
    config.wrap = false;
    config.keyboard = false;
    config.pauseOnHover = false;
    config.wrap = true;
  }

  data: any;

  ngOnInit() {

    if (environment.first_exchange !== '') {
      this.showFirstExchange = true;
      this.first_exchange = environment.first_exchange;
    } else {
      this.showFirstExchange = false;
    }

    this.UpdateCurrentDate();

    setInterval(this.UpdateCurrentDate, 1800000);

    setInterval(this.UpdateClock, 0);

    this.InitialFunc();

  }


  AnimateRates() {

    var $this = this;

    window.setTimeout(function () {

      var myInterval;
      var div = $('#messagewindow');

      if (div) {

        myInterval = setInterval(function () {

          var pos = div.scrollTop();

          div.scrollTop(pos + 2.25);

          if (div.length > 0) {

            if (div.scrollTop() + div.innerHeight() >= div[0].scrollHeight - 2) {
              if (myInterval) {
                clearInterval(myInterval);
                myInterval = null;
              }

              if ($this.favorriteInterval) {
                clearInterval($this.favorriteInterval);
                $this.favorriteInterval = null;
              }

              //Starting next iteration with new data
              window.setTimeout(function () {
                div.scrollTop(0);
                $this.InitialFunc();
              }, 1000);
            }
          }
        }, 40);
      }

    }, 1000);
  }

  UpdateCurrentDate() {

    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const today = new Date();
    this.currentDate = today.getDate() + ' ' + monthNames[today.getMonth()] + ' ' + today.getFullYear();
    $('#clock2').html(this.currentDate);
    //return this.currentDate;
  }

  UpdateClock() {

    const currentTime = new Date();
    let currentHours = currentTime.getHours();
    const currentMinutes = currentTime.getMinutes();
    const currentSeconds = currentTime.getSeconds();
    const Minutes = (currentMinutes < 10 ? '0' : '') + currentMinutes;
    const Seconds = (currentSeconds < 10 ? '0' : '') + currentSeconds;
    const timeOfDay = (currentHours < 12) ? 'AM' : 'PM';
    currentHours = (currentHours > 12) ? currentHours - 12 : currentHours;
    currentHours = (currentHours === 0) ? 12 : currentHours;
    this.currentTimeString = currentHours + ':' + Minutes + ':' + Seconds + ' ' + timeOfDay;
    $('#clock').html(this.currentTimeString);
  }

  InitialFunc() {

    this.generalService.getAllByBranch()
      .subscribe((data: any) => {
        console.log("Response Data : ", data.responsedata)
        this.allRates = data.responsedata;
        this.favourites = [];
        $('#favitems').empty();
        for (const elem in this.allRates) {
          if (elem) {
            if (this.allRates[elem].isfavourite === '1') {
              this.favourites.push(this.allRates[elem]);
              $('#favitems').append('<li>' + this.allRates[elem].forexremittancerate + '</li>&nbsp;&nbsp;&nbsp;&nbsp;' + this.allRates[elem].currencyname);
            }
          }
        }
        this.sliderFunction();
      });
    this.AnimateRates();

    // marqueejs.$('.simple-marquee-container').SimpleMarquee();
  }

  // For Islamic Exchange

  sliderFunction() {

    let favoritehtml = '';
    const $favorite = $('#divfavorite');
    $favorite.empty();

    $.each(this.favourites, function (index, item) {

      favoritehtml = '<div style="height: 100px"><img style="width:50px; height: 30px;" src="http://exrate.islamicex.com/assets/flags/qa.png">' +
        '&nbsp;&nbsp;&nbsp;&nbsp' +
        '<span style="font-weight: 700;font-size: 30px;text-shadow: 1px 1px 2px black; ">' + '1 QAR' + '&nbsp;&nbsp;&nbsp;&nbsp'
        + '=' + '</span>' +
        '&nbsp;&nbsp;&nbsp;&nbsp' +
        '<span style="font-weight: 700;font-size: 30px;text-shadow: 1px 1px 2px black; ">' + item.forexremittancerate + '</span>' +
        '&nbsp;&nbsp;&nbsp;&nbsp;' +

        '<span style="font-weight: 700;font-size: 30px;text-shadow: 1px 1px 2px black; ">' + item.currencyname +
        '&nbsp;&nbsp;&nbsp;&nbsp;<img style="width:50px; height: 30px;" src=' + item.countryimage + '>' +
        '</span>'
        + '</div>';

      $favorite.append(favoritehtml);
    });

    $('#divfavorite > div:gt(0)').hide();

    this.favorriteInterval = setInterval(function() {

      $('#divfavorite > div:first').fadeOut(2000, function(){
        $(this).next()
        .fadeIn(2000)
        .end()
        .appendTo('#divfavorite');
      });    
    },  5000);
  }


  // For PK Exchange

  // sliderFunction() {

  //   let favoritehtml = '';
  //   const $favorite = $('#divfavorite');
  //   $favorite.empty();

  //   $.each(this.favourites, function (index, item) {

  //     favoritehtml = '<div style="height: 100px"><img style="width:50px; height: 30px;" src="http://192.168.40.30:81/assets/flags/qa.png">' +
  //       '&nbsp;&nbsp;&nbsp;&nbsp' +
  //       '<span style="font-weight: 700;font-size: 30px;text-shadow: 1px 1px 2px black; ">' + '1' + ' ' + environment.currency + '&nbsp;&nbsp;&nbsp;&nbsp'
  //       + '=' + '</span>' +
  //       '&nbsp;&nbsp;&nbsp;&nbsp' +
  //       '<span style="font-weight: 700;font-size: 30px;text-shadow: 1px 1px 2px black; ">' + item.forexremittancerate + '</span>' +
  //       '&nbsp;&nbsp;&nbsp;&nbsp;' +

  //       '<span style="font-weight: 700;font-size: 30px;text-shadow: 1px 1px 2px black; ">' + item.currencyname +
  //       '&nbsp;&nbsp;&nbsp;&nbsp;<img style="width:50px; height: 30px;" src=' + item.countryimage + '>' +
  //       '</span>'
  //       + '</div>';

  //     $favorite.append(favoritehtml);
  //   });

  //   $('#divfavorite > div:gt(0)').hide();

  //   this.favorriteInterval = setInterval(function () {

  //     $('#divfavorite > div:first').fadeOut(2000, function () {
  //       $(this).next()
  //         .fadeIn(2000)
  //         .end()
  //         .appendTo('#divfavorite');
  //     });
  //   }, 5000);
  // }



  // For UniMoni Exchange

  // sliderFunction() {

  //   let favoritehtml = '';
  //   const $favorite = $('#divfavorite');
  //   $favorite.empty();

  //   $.each(this.favourites, function (index, item) {

  //     favoritehtml = '<div style="height: 100px"><img style="width:50px; height: 30px;" src="https://unimonirates.thoughtbox.io/assets/flags/qa.png">' +
  //       '&nbsp;&nbsp;&nbsp;&nbsp' +
  //       '<span style="font-weight: 700;font-size: 30px;text-shadow: 1px 1px 2px black; ">' + '1' + ' ' + environment.currency + '&nbsp;&nbsp;&nbsp;&nbsp'
  //       + '=' + '</span>' +
  //       '&nbsp;&nbsp;&nbsp;&nbsp' +
  //       '<span style="font-weight: 700;font-size: 30px;text-shadow: 1px 1px 2px black; ">' + item.forexremittancerate + '</span>' +
  //       '&nbsp;&nbsp;&nbsp;&nbsp;' +

  //       '<span style="font-weight: 700;font-size: 30px;text-shadow: 1px 1px 2px black; ">' + item.currencyname +
  //       '&nbsp;&nbsp;&nbsp;&nbsp;<img style="width:50px; height: 30px;" src=' + item.countryimage + '>' +
  //       '</span>'
  //       + '</div>';

  //     $favorite.append(favoritehtml);
  //   });

  //   $('#divfavorite > div:gt(0)').hide();

  //   this.favorriteInterval = setInterval(function () {

  //     $('#divfavorite > div:first').fadeOut(2000, function () {
  //       $(this).next()
  //         .fadeIn(2000)
  //         .end()
  //         .appendTo('#divfavorite');
  //     });
  //   }, 5000);
  // }

  mainDashboard() {
    this.router.navigate(['/main']);
  }
}
